import actions from '@state/actions/constants';
import defaultState from '@state/default-state';
/**
 * Updates a particular sub-section of state.
 * @param {object} state passed in automatically be redux
 * @param {object} action the redux action that has been dispatched
 * @returns {object} A new object that replaces state.
 */
export default (state = defaultState.gtm, action) => {
  switch (action.type) {
    case actions.GTM_GLOBAL_INFO: {
      return {
        ...action.payload.info
      };
    }
  }

  return state;
};
