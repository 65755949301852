/**
 * @description A central collection of site wide available actions types
 */

export default {
  OPEN_ADD_TO_COLLECTION: `OPEN_ADD_TO_COLLECTION`,
  CLOSE_ADD_TO_COLLECTION: `CLOSE_ADD_TO_COLLECTION`,
  ADD_TO_COLLECTION: `ADD_TO_COLLECTION`,
  OPEN_REMOVE_FROM_COLLECTION: `OPEN_REMOVE_FROM_COLLECTION`,
  CLOSE_REMOVE_FROM_COLLECTION: `CLOSE_REMOVE_FROM_COLLECTION`,
  REMOVE_FROM_COLLECTION: `REMOVE_FROM_COLLECTION`,
  REMOVE_COLLECTION: `REMOVE_COLLECTION`,
  GET_COLLECTIONS: `GET_COLLECTIONS`,
  SET_COLLECTIONS: `SET_COLLECTIONS`,
  GET_COLLECTION: `GET_COLLECTION`,
  SET_COLLECTION: `SET_COLLECTION`,
  CLEAR_COLLECTION: `CLEAR_COLLECTION`,
  CREATE_INSIGHT: `CREATE_INSIGHT`,
  ADD_INSIGHT: `ADD_INSIGHT`,
  REMOVE_INSIGHT: `REMOVE_INSIGHT`,
  OPEN_VIEW_JOURNEY: `OPEN_VIEW_JOURNEY`,
  CLOSE_VIEW_JOURNEY: `CLOSE_VIEW_JOURNEY`,
  OPEN_MS_FORMS: `OPEN_MS_FORMS`,
  CLOSE_MS_FORMS: `CLOSE_MS_FORMS`,
  OPEN_STAGE: `OPEN_STAGE`,
  CLOSE_STAGE: `CLOSE_STAGE`,
  OPEN_CREATE_COLLECTION: `OPEN_CREATE_COLLECTION`,
  OPEN_QUICK_CREATE_COLLECTION: `OPEN_QUICK_CREATE_COLLECTION`,
  CLOSE_CREATE_COLLECTION: `CLOSE_CREATE_COLLECTION`,
  CREATE_COLLECTION: `CREATE_COLLECTION`,
  COLLECTION_CREATED: `COLLECTION_CREATED`,
  EDIT_COLLECTION: `EDIT_COLLECTION`,
  COLLECTION_EDITED: `COLLECTION_EDITED`,
  ADD_TOAST: `ADD_TOAST`,
  REMOVE_TOAST: `REMOVE_TOAST`,
  GTM_GLOBAL_INFO: `GTM_GLOBAL_INFO`,
  ERROR: `ERROR`
};
