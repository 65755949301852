import { combineReducers } from 'redux';
import collections from './collections';
import toasts from './toasts';
import gtm from './gtm';
import viewJourney from './view-journey';
import msForms from './ms-forms';
import stage from './stage';

/**
 * @description A list of combined reducers used to update state.
 * @example
 * // Each reducer manages a separate area of state:
 * {
 *   reducerName: { ... }
 * }
 */
export default combineReducers({
  viewJourney,
  msForms,
  stage,
  toasts,
  collections,
  gtm
});
