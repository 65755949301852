import actionsTypes from '../actions/constants';
import actions from '../actions';

export default ({ dispatch }) => (next) => (action) => {
  switch (action.type) {
    case actionsTypes.ADD_TOAST: {
      setTimeout(() => {
        dispatch(actions.removeToast());
      }, process.env.TOAST_TIMEOUT || 3000);

      return next(action);
    }

    default:
      return next(action);
  }
};
