import React from 'react';
import { Provider } from 'react-redux';

import createStore from '../store/create';
import actions from '../actions';

export default ({ element }) => {
  const store = createStore();

  store.dispatch(actions.getCollections());

  return <Provider store={store}>{element}</Provider>;
};
