export default () => (next) => (action) => {
  const gtmActions = {
    OPEN_ADD_TO_COLLECTION: `modal opened`,
    ADD_TO_COLLECTION: `added to collection`,
    OPEN_QUICK_CREATE_COLLECTION: `create new collection`,
    CREATE_COLLECTION: `new collection created`
  };

  if (gtmActions[action.type]) {
    dataLayer.push({
      event: `collection`,
      action: gtmActions[action.type],
      collection_add_type: `modal`,
      collection_item: {
        type: action.payload.type,
        ...action.payload.item
      }
    });
  }

  return next(action);
};
