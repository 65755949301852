import actions from '@state/actions/constants';
import defaultState from '@state/default-state';
/**
 * Updates a particular sub-section of state.
 * @param {object} state passed in automatically be redux
 * @param {object} action the redux action that has been dispatched
 * @returns {object} A new object that replaces state.
 */
export default (state = defaultState.stage, action) => {
  switch (action.type) {
    case actions.OPEN_STAGE: {
      return {
        modal: { isOpen: true },
        data: action.payload
      };
    }

    case actions.CLOSE_STAGE: {
      return { modal: { isOpen: false } };
    }
  }

  return state;
};
