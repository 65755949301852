/**
 * @description A central collection of site wide available actions
 * @example
 * // Use redux to dispatch the action
 * store.dispatch(actionTypes.DEMO, { payload });
 */

import actions from './constants';

export default {
  /**
   * Error action
   * @param {object} error An error object to add to state
   * @return {object} action
   */
  error: (error) => ({
    type: actions.ERROR,
    payload: {
      error
    }
  }),

  /**
   * Add Toast
   * @param {string} body the text of the toast
   * @returns {object} action
   */
  addToast: (body) => ({
    type: actions.ADD_TOAST,
    payload: body
  }),

  /**
   * Removes oldest toast
   * @returns {object} action
   */
  removeToast: () => ({
    type: actions.REMOVE_TOAST
  }),

  /**
   * Get Collections
   * @returns {object} action
   */
  getCollections: () => ({
    type: actions.GET_COLLECTIONS
  }),

  /**
   * Set Collections from API response
   * @param {Array} collections the list of collections to add to state
   * @returns {object} action
   */
  setCollections: (collections) => ({
    type: actions.SET_COLLECTIONS,
    payload: {
      collections
    }
  }),

  /**
   * Remove Collection action
   * @param {object} collection the collection to remove
   * @returns {object} action
   */
  removeCollection: (collection) => ({
    type: actions.REMOVE_COLLECTION,
    payload: collection
  }),

  /**
   * Add to Collection action
   * @param {object} collection the collection to add the item to
   * @param {string} type the content type of the item to be added
   * @param {object} item the item to add to the collection
   * @returns {object} action
   */
  addToCollection: (collection, type, item) => ({
    type: actions.ADD_TO_COLLECTION,
    payload: {
      collection,
      type,
      item
    }
  }),

  /**
   * Remove from Collection action
   * @param {object} collection the collection to remove the item from
   * @param {string} type the content type of the item to be removed
   * @param {string} itemId the id of the item to remove from the collection
   * @param {bool} returnCollection toggles to return the collection in the state
   * @returns {object} action
   */
  removeFromCollection: (collection, type, itemId, returnCollection) => ({
    type: actions.REMOVE_FROM_COLLECTION,
    payload: {
      collection,
      type,
      itemId,
      returnCollection
    }
  }),

  /**
   * Collection Created action
   * @param {object} collection the collection object that has been createed
   * @returns {object} action
   */
  collectionCreated: (collection) => ({
    type: actions.COLLECTION_CREATED,
    payload: collection
  }),

  /**
   * Open view journey download formats modal
   * @param {string} species the species type of journey
   * @returns {object} action
   */
  openViewJourney: (species) => ({
    type: actions.OPEN_VIEW_JOURNEY,
    payload: species
  }),

  /**
   * Close view journey download formats modal
   * @returns {object} action
   */
  closeViewJourney: () => ({
    type: actions.CLOSE_VIEW_JOURNEY
  }),

  /**
   * Open view journey download formats modal
   * @param {object} data the species type of journey
   * @returns {object} action
   */
   openStage: (data) => ({
    type: actions.OPEN_STAGE,
    payload: data
  }),

  /**
   * Close view journey download formats modal
   * @returns {object} action
   */
  closeStage: () => ({
    type: actions.CLOSE_STAGE
  }),

  /**
   * Open create collection modal
   * @returns {object} action
   */
  openCreateCollection: () => ({
    type: actions.OPEN_CREATE_COLLECTION
  }),

  /**
   * Closes create collection modal
   * @returns {object} action
   */
  closeCreateCollection: () => ({
    type: actions.CLOSE_CREATE_COLLECTION
  }),

   /**
   * Open MS Forms modal
   * @returns {object} action
   */
    openMsForms: () => ({
      type: actions.OPEN_MS_FORMS
    }),

    /**
     * Closes MS Forms modal
     * @returns {object} action
     */
    closeMsForms: () => ({
      type: actions.CLOSE_MS_FORMS
    }),

  /**
   * Create Collection action
   * @param {object} collection the collection object to be created
   * @param {object} item the snippet to add to the collection
   * @param {string} type the type of content to add to the collection
   * @returns {object} action
   */
  createCollection: (collection, item, type) => ({
    type: actions.CREATE_COLLECTION,
    payload: {
      collection,
      item,
      type
    }
  }),

  /**
   * Edit Collection action
   * @param {object} collection the collection object to be edited
   * @returns {object} action
   */
  editCollection: (collection) => ({
    type: actions.EDIT_COLLECTION,
    payload: collection
  }),

  /**
   * Collection edited  action
   * @param {object} collection the collection object that has been edited
   * @returns {object} action
   */
  collectionEdited: (collection) => ({
    type: actions.COLLECTION_EDITED,
    payload: collection
  }),

  /**
   * Open add to collection modal
   * @param {string} type the type of content to add to the collection
   * @param {object} item the snippet to add to the collection
   * @param {node} focusOnCloseRef DOM node that should recieve focus when the Modal is closed
   * @returns {object} action
   */
  openAddToCollection: (type, item, focusOnCloseRef) => ({
    type: actions.OPEN_ADD_TO_COLLECTION,
    payload: {
      type,
      item,
      focusOnCloseRef
    }
  }),

  /**
   * Open add to collection form in add to collection modal
   * @param {string} type the type of content to add to the collection
   * @param {object} item the snippet to add to the collection
   * @returns {object} action
   */
  openQuickCreateCollection: (type, item) => ({
    type: actions.OPEN_QUICK_CREATE_COLLECTION,
    payload: {
      type,
      item
    }
  }),

  /**
   * Closes add to collection modal
   * @returns {object} action
   */
  closeAddToCollection: () => ({
    type: actions.CLOSE_ADD_TO_COLLECTION
  }),

  /**
   * Open remove from collection dialog
   * @param {string} type the type of content to remove from the collection
   * @param {string} itemId the snippet to remove from the collection
   * @param {node} focusOnCloseRef DOM node that should recieve focus when the Modal is closed
   * @returns {object} action
   */
  openRemoveFromCollection: (type, itemId, focusOnCloseRef) => ({
    type: actions.OPEN_REMOVE_FROM_COLLECTION,
    payload: {
      type,
      itemId,
      focusOnCloseRef
    }
  }),

  /**
   * Closes remove from collection dialog
   * @returns {object} action
   */
  closeRemoveFromCollection: () => ({
    type: actions.CLOSE_REMOVE_FROM_COLLECTION
  }),

  /**
   * Get Collection action
   * @param {string} collectionId the id of the collection to fetch
   * @returns {object} action
   */
  getCollection: (collectionId) => ({
    type: actions.GET_COLLECTION,
    payload: {
      collectionId
    }
  }),

  /**
   * Set Collection action
   * @param {string} collection the collection to view
   * @returns {object} action
   */
  setCollection: (collection) => ({
    type: actions.SET_COLLECTION,
    payload: {
      collection
    }
  }),

  /**
   * Clear Collection action
   * @returns {object} action
   */
  clearCollection: () => ({
    type: actions.CLEAR_COLLECTION
  }),

  /**
   * Create insight
   * @param {string} text the insight text
   * @returns {object} action
   */
  createInsight: (text) => ({
    type: actions.CREATE_INSIGHT,
    payload: {
      text
    }
  }),

  /**
   * Add an insight to the current collection
   * @param {object} insight the insight
   * @returns {object} action
   */
  addInsight: (insight) => ({
    type: actions.ADD_INSIGHT,
    payload: {
      insight
    }
  }),

  /**
   * Add an insight to the current collection
   * @param {string} insightId the id of the insight to remove
   * @returns {object} action
   */
  removeInsight: (insightId) => ({
    type: actions.REMOVE_INSIGHT,
    payload: {
      insightId
    }
  }),

  /**
   * Add GTM info
   * @param {object} info the info to add
   * @returns {object} action
   */
  setGTMGlobalInfo: (info) => ({
    type: actions.GTM_GLOBAL_INFO,
    payload: {
      info
    }
  })
};
