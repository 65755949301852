import error from './error';
import collections from './collections';
import toasts from './toasts';
import gtm from './gtm';

/**
 * @description A list of middlewares combined within the createStore function.
 * Used primarily to house your async API calls.
 */
export default [gtm, toasts, collections, error];
