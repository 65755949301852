import actions from '@state/actions/constants';
import defaultState from '@state/default-state';
/**
 * Updates a particular sub-section of state.
 * @param {object} state passed in automatically be redux
 * @param {object} action the redux action that has been dispatched
 * @returns {object} A new object that replaces state.
 */
export default (state = defaultState.toasts, action) => {
  switch (action.type) {
    case actions.ADD_TOAST: {
      const notifications = [...state.notifications];
      notifications.push(action.payload);

      return {
        ...state,
        notifications
      };
    }

    case actions.REMOVE_TOAST: {
      const notifications = [...state.notifications];
      notifications.shift();

      return {
        ...state,
        notifications
      };
    }
  }

  return state;
};
