import 'prismjs/themes/prism.css';

import '@styles/site.scss';

import wrapWithProvider from './src/state/providers/redux';
export const wrapRootElement = wrapWithProvider;

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof userpilot !== `undefined`) {
    prevLocation && prevLocation !== location && userpilot.reload();
  }
};

const addScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript('https://footer.mars.com/js/footer.js.aspx?spot-mars-com');
  };
};
