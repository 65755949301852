import actionsTypes from '../actions/constants';
import actions from '../actions';
import { collectionService } from '../services';

export default (store) => (next) => (action) => {
  const dispatch = store.dispatch;
  const { collections: state } = store.getState();
  const service = collectionService(dispatch);

  switch (action.type) {
    case actionsTypes.GET_COLLECTIONS: {
      service.getCollections();
      return next(action);
    }

    case actionsTypes.ADD_TO_COLLECTION: {
      const { collection, type, item } = action.payload;
      service.addToCollection(collection.id, type, item.id);
      return next(action);
    }

    case actionsTypes.REMOVE_COLLECTION: {
      const { collection } = action.payload;
      service.removeCollection(collection.id);
      return next(action);
    }

    case actionsTypes.REMOVE_FROM_COLLECTION: {
      const { collection, type, itemId } = action.payload;
      service.removeFromCollection(collection.id, type, itemId);
      return next(action);
    }

    case actionsTypes.GET_COLLECTION: {
      service.getCollection(action.payload.collectionId);
      return next(action);
    }

    case actionsTypes.CREATE_INSIGHT: {
      service.createInsight(state.collection.id, action.payload.text);
      return next(action);
    }

    case actionsTypes.REMOVE_INSIGHT: {
      service.removeInsight(state.collection.id, action.payload.insightId);
      return next(action);
    }

    case actionsTypes.CREATE_COLLECTION: {
      service.createCollection(action.payload.collection);
      return next(action);
    }

    case actionsTypes.COLLECTION_CREATED: {
      dispatch(actions.addToast(`New collection successfully created`));
      return next(action);
    }

    case actionsTypes.EDIT_COLLECTION: {
      service.editCollection(action.payload);
      return next(action);
    }

    default:
      return next(action);
  }
};
