import actions from '@state/actions/constants';
import defaultState from '@state/default-state';
/**
 * Updates a particular sub-section of state.
 * @param {object} state passed in automatically be redux
 * @param {object} action the redux action that has been dispatched
 * @returns {object} A new object that replaces state.
 */
export default (state = defaultState.collections, action) => {
  switch (action.type) {
    case actions.SET_COLLECTIONS: {
      return {
        ...state,
        collections: action.payload.collections
      };
    }

    case actions.OPEN_ADD_TO_COLLECTION: {
      return {
        ...state,
        modal: {
          isOpen: true,
          type: action.payload.type,
          item: action.payload.item,
          focusOnCloseRef: action.payload.focusOnCloseRef
        }
      };
    }

    case actions.CLOSE_ADD_TO_COLLECTION: {
      return {
        ...state,
        modal: {
          isOpen: false
        }
      };
    }

    case actions.ADD_TO_COLLECTION: {
      const { collection, type, item } = action.payload;

      const collections = [...state.collections];
      const collectionFromState = collections.find(
        ({ id }) => id == collection.id
      );

      if (!collectionFromState[type].includes(item.id)) {
        collectionFromState[type].push(item.id);
      }

      return {
        ...state,
        collections
      };
    }

    case actions.OPEN_REMOVE_FROM_COLLECTION: {
      return {
        ...state,
        dialog: {
          isOpen: true,
          type: action.payload.type,
          itemId: action.payload.itemId,
          focusOnCloseRef: action.payload.focusOnCloseRef
        }
      };
    }

    case actions.CLOSE_REMOVE_FROM_COLLECTION: {
      return {
        ...state,
        dialog: {
          isOpen: false
        }
      };
    }

    case actions.REMOVE_FROM_COLLECTION: {
      const { type, itemId, collection: selectedColleciton, returnCollection } = action.payload;
      let collection = { ...selectedColleciton };
      collection[type] = collection[type].filter((item) => item.id !== itemId);
      
      if ( returnCollection === true) {
        return {
          ...state
          ,collection
        };
      } else {
        return {
          ...state
        };
      }
    }

    case actions.COLLECTION_EDITED: {
      state.collection = {
        ...state.collection,
        ...action.payload
      };
      const collections = state.collections.map((collection) => {
        if (collection.id === action.payload.id) {
          return { ...collection, ...action.payload };
        }
        return collection;
      });

      return {
        ...state,
        collections
      };
    }

    case actions.OPEN_CREATE_COLLECTION: {
      return {
        ...state,
        createCollectionModal: {
          isOpen: true
        }
      };
    }

    case actions.CLOSE_CREATE_COLLECTION: {
      return {
        ...state,
        createCollectionModal: {
          isOpen: false
        }
      };
    }

    case actions.COLLECTION_CREATED: {
      const collection = {
        ...action.payload,
        contentSnippets: [],
        dataSnippets: [],
        moments: [],
        insightStarterPoints: []
      };
      const collections = [...state.collections, collection];

      return {
        ...state,
        collections
      };
    }

    case actions.SET_COLLECTION: {
      const { collection } = action.payload;

      return {
        ...state,
        collection
      };
    }

    case actions.ADD_INSIGHT: {
      const { insight } = action.payload;
      const collection = { ...state.collection };
      collection.insights.push(insight);

      return {
        ...state,
        collection
      };
    }

    case actions.REMOVE_INSIGHT: {
      const { insightId } = action.payload;
      const collection = { ...state.collection };
      collection.insights = collection.insights.filter(
        (insight) => insight.id !== insightId
      );

      return {
        ...state,
        collection
      };
    }

    case actions.CLEAR_COLLECTION: {
      const newState = { ...state };
      newState.collection = undefined;

      return newState;
    }
  }

  return state;
};
