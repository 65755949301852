import axios from 'axios';
import actions from '../../actions';

const COLLECTIONS_ENDPOINT = `/api/collections`;
const types = {
  contentSnippets: `content_snippets`,
  dataSnippets: `data_snippets`,
  moments: `moments`,
  insightStarterPoints: `starter_points`,
  insights: `insights`,
  articles: `articles`
};


/**
 * A series of collection related API calls.
 * @param {function} dispatch the redux store dispatch function to apply to the services.
 * @returns {object} A set of collection specific API service call functions.
 */
export default (dispatch) => ({
  getCollections: () => {
    return axios
      .get(COLLECTIONS_ENDPOINT)
      .then((res) => {

        if (typeof res.data.error !== `undefined`) {
          dispatch(actions.addToast(`Error while fetching your collections - please contact the administrator.`));
        }

        dispatch(actions.setCollections(res.data))
      })
      .catch((e) => dispatch(actions.error(e)));
  },

  createCollection: (collection) => {
    return axios
      .post(COLLECTIONS_ENDPOINT, collection, {
        headers: {
          'Content-Type': `application/json`
        }
      })
      .then((res) => dispatch(actions.collectionCreated(res.data)))
      .catch((e) => dispatch(actions.error(e)));
  },

  getCollection: (collectionId) => {
    return axios
      .get(`${COLLECTIONS_ENDPOINT}/${collectionId}`)
      .then((res) => {

        if (typeof res.data.error !== `undefined`) {
          dispatch(actions.addToast(`Error while fetching your collection - please contact the administrator.`));
        }

        dispatch(actions.setCollection(res.data))
      })
      .catch((e) => dispatch(actions.error(e)));
  },

  editCollection: (collection) => {
    const { id, title, description } = collection;

    return axios
      .put(
        `${COLLECTIONS_ENDPOINT}/${id}`,
        {
          title,
          description
        },
        {
          headers: {
            'Content-Type': `application/json`
          }
        }
      )
      .then(() => dispatch(actions.collectionEdited(collection)))
      .catch((e) => dispatch(actions.error(e)));
  },

  removeCollection: (collectionId) => {
    return axios
      .delete(`${COLLECTIONS_ENDPOINT}/${collectionId}`)
      .catch((e) => dispatch(actions.error(e)));
  },

  addToCollection: (collectionId, type, id) => {
    return axios
      .post(
        `${COLLECTIONS_ENDPOINT}/${collectionId}/${types[type]}`,
        {
          id
        },
        {
          headers: {
            'Content-Type': `application/json`
          }
        }
      )
      .catch((e) => dispatch(actions.error(e)));
  },

  removeFromCollection: (collectionId, type, id) => {
    return axios
      .delete(`${COLLECTIONS_ENDPOINT}/${collectionId}/${types[type]}/${id}`)
      .catch((e) => dispatch(actions.error(e)));
  },

  createInsight: (collectionId, text) => {
    return axios
      .post(
        `${COLLECTIONS_ENDPOINT}/${collectionId}/insights`,
        {
          text
        },
        {
          headers: {
            'Content-Type': `application/json`
          }
        }
      )
      .then((res) => dispatch(actions.addInsight(res.data)))
      .catch((e) => dispatch(actions.error(e)));
  },

  removeInsight: (collectionId, insightId) => {
    return axios
      .delete(`${COLLECTIONS_ENDPOINT}/${collectionId}/insights/${insightId}`)
      .catch((e) => dispatch(actions.error(e)));
  }
});
