import axios from 'axios';
import collectionService from './collections/collections';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if (typeof window !== `undefined`)
        window.location.replace(error.response.data);
    }
    return error;
  }
);

export { collectionService };
