import actionsTypes from '../actions/constants';
import actions from '../actions';

export default ({ dispatch }) => (next) => (action) => {
  switch (action.type) {
    case actionsTypes.ERROR: {
      dispatch(actions.addToast(`A problem occured...`));
      return next(action);
    }

    default:
      return next(action);
  }
};
