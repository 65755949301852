export default {
  toasts: {
    notifications: []
  },
  collections: {
    modal: {
      isOpen: false
    },
    dialog: {
      isOpen: false
    },
    createCollectionModal: {
      isOpen: false
    },
    collections: [],
    collection: undefined
  },
  gtm: {},
  viewJourney: {
    modal: {
      isOpen: false
    }
  },
  msForms: {
    modal: {
      isOpen: false
    }
  },
  stage: {
    modal: {
      isOpen: false
    },
    data: {
      title: `Cat`,
      description: `The description`,
      secondaryImage: undefined,
      url: `/cat/acquisition`,
      species: `cat`
    }
  }
};
