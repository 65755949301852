exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-my-collections-js": () => import("./../../../src/pages/my-collections.js" /* webpackChunkName: "component---src-pages-my-collections-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/article/article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-journey-page-journey-page-js": () => import("./../../../src/templates/journey-page/journey-page.js" /* webpackChunkName: "component---src-templates-journey-page-journey-page-js" */),
  "component---src-templates-landing-page-landing-page-js": () => import("./../../../src/templates/landing-page/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-landing-page-js" */),
  "component---src-templates-moment-moment-js": () => import("./../../../src/templates/moment/moment.js" /* webpackChunkName: "component---src-templates-moment-moment-js" */),
  "component---src-templates-stage-stage-js": () => import("./../../../src/templates/stage/stage.js" /* webpackChunkName: "component---src-templates-stage-stage-js" */)
}

