import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import middlewares from '../middlewares';
import reducers from '../reducers';

const combinedMiddlewares = [...middlewares];

export default () =>
  createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(...combinedMiddlewares))
  );
